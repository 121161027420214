<template>
  <div class="item-class-show">
    <!--    <Row-->
    <!--      class="item-class-group"-->
    <!--    >-->
    <!--      <i-col class="item-class-name" span="2">品牌 :</i-col>-->
    <!--      <Col span="22" class="item-class-right">-->
    <!--        <span v-for="(brand, index) in brandList" :key="`brand-${index}`">-->
    <!--          {{-->
    <!--            brand.brand_name-->
    <!--          }}-->
    <!--        </span>-->
    <!--      </Col>-->
    <!--    </Row>-->
    <Row type="flex" align="middle" class="item-class-group">
      <i-col span="2">{{ $t('综合排序') }}:</i-col>
      <i-col class="item-class-select" span="22">
        <span v-for="(item, i) in sortList" :key="`sortItem-${i}`" @click="onSelct(item.type)">
          {{ $t(item.name) }}
          <!-- <div class="drop-wrap">
            <Icon type="md-arrow-dropup" />
            <Icon type="md-arrow-dropdown" />
          </div> -->
        </span>
      </i-col>
    </Row>
  </div>
</template>

<script>
export default {
  name: 'GoodsClassNav',
  props: {
    brandList: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      formItem: {
        startPrice: 0,
        endPrice: 0,
      },
      sortList: [
        {
          type: 'default',
          name: '默认',
        },
        {
          type: 'sales',
          name: '销量',
        },
        {
          type: 'mark',
          name: '商品评分',
        },
        {
          type: 'price',
          name: '价格',
        },
      ],
      p_t: 'low_height'
    };
  },

  methods: {
    onSelct(t) {
      if (t === 'sales') {
        this.$emit('onSelct', 'zonghe');
      } else if (t === 'mark') {
        this.$emit('onSelct', 'deal_num');
      } else if (t === 'price') {
        this.p_t = this.p_t === 'low_height' ? 'height_low' : 'low_height'
        this.$emit('onSelct', this.p_t);
      } else {
        this.$emit('onSelct', '');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/common/style/common.scss";

.item-class-show {
  margin: 15px auto;
  width: 100%;
  padding: 4px 10px;
  background-color: #fff;
}

.item-class-right {
  display: flex;
  align-items: center;
}

.item-class-group {
  margin-top: 1px;
  height: 45px;
}

.item-class-name {
  padding-left: 15px;
  line-height: 44px;
  color: #969696;
}

.item-class-name:first-child {
  line-height: 43px;
}

.item-class-select {
  border-bottom: 1px solid #f5f5f5;

  &:last-child {
    border: none;
  }
}

.item-class-select span {
  margin-right: 20px;
  color: #333;
  line-height: 45px;
  cursor: pointer;
  display: inline-block;
}

.drop-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
