<template>
  <div class="box">
    <div class="nav w">
      <ul class="location">

      </ul>
      <ul class="detail">
        <li class="first flex" v-if="!token">
          <router-link to="/Login">{{ $t('登录') }}</router-link>
          /<span class="text-color-red"><router-link to="/Register">{{ $t('注册') }}</router-link></span>
        </li>
        <li class="first" v-if="token">
          <router-link :to="{ path: '/my',query:{name:'MyAccount'}}"
          >
            <Avatar icon="ios-person" size="small" style="background-color: #87d068"/>
            {{ userInfo.user_name }}
          </router-link
          >
        </li>
        <li class="first" v-if="token">
          <router-link :to="{ path: 'My', query: { name: 'MyOrder' } }"
          >{{ $t('我的订单') }}
          </router-link
          >
        </li>
        <li class="first" v-if="token">
          <router-link :to="{ path: 'My', query: { name: 'Balance' } }">{{ $t('余额') }}</router-link>
        </li>
        <li>
          <Dropdown placement="bottom-start">
            <a href="javascript:void(0)">
              <Icon type="ios-cart-outline"></Icon>
              {{ $t('购物车') }}
            </a>
            <DropdownMenu slot="list">
              <div class="shopping-cart-null" v-show="shoppingCart.length <= 0">
                <Icon type="ios-cart-outline" class="cart-null-icon"></Icon>
                <span>{{ $t('你的购物车没有空空哦') }}</span>
                <span>{{ $t('赶快去添加商品吧~') }}</span>
              </div>
              <div class="shopping-cart-list" v-show="shoppingCart.length > 0">
                <div
                    class="shopping-cart-box"
                    v-for="(item, index) in shoppingCart"
                    :key="index"
                >
                  <div class="shopping-cart-img">
                    <img :src="item.img"/>
                  </div>
                  <div class="shopping-cart-info">
                    <div class="shopping-cart-title">
                      <p>{{ item.title.substring(0, 22) }}...</p>
                    </div>
                    <div class="shopping-cart-detail">
                      <p>
                        套餐:
                        <span class="shopping-cart-text">
                          {{ item.package }}
                        </span>
                        数量:
                        <span class="shopping-cart-text">
                          {{ item.count }}
                        </span>
                        价钱:
                        <span class="shopping-cart-text">
                          {{ item.price }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="shopping-cart-list" v-show="shoppingCart.length > 0">
                <div
                    class="shopping-cart-box"
                    v-for="(item, index) in shoppingCart"
                    :key="index"
                >
                  <div class="shopping-cart-img">
                    <img :src="item.img"/>
                  </div>
                  <div class="shopping-cart-info">
                    <div class="shopping-cart-title">
                      <p>{{ item.title.substring(0, 22) }}...</p>
                    </div>
                    <div class="shopping-cart-detail">
                      <p>
                        套餐:
                        <span class="shopping-cart-text">
                          {{ item.package }}
                        </span>
                        数量:
                        <span class="shopping-cart-text">
                          {{ item.count }}
                        </span>
                        价钱:
                        <span class="shopping-cart-text">
                          {{ item.price }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="shopping-cart-list" v-show="shoppingCart.length > 0">
                <div
                    class="shopping-cart-box"
                    v-for="(item, index) in shoppingCart"
                    :key="index"
                >
                  <div class="shopping-cart-img">
                    <img :src="item.img"/>
                  </div>
                  <div class="shopping-cart-info">
                    <div class="shopping-cart-title">
                      <p>{{ item.title.substring(0, 22) }}...</p>
                    </div>
                    <div class="shopping-cart-detail">
                      <p>
                        套餐:
                        <span class="shopping-cart-text">
                          {{ item.package }}
                        </span>
                        数量:
                        <span class="shopping-cart-text">
                          {{ item.count }}
                        </span>
                        价钱:
                        <span class="shopping-cart-text">
                          {{ item.price }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="shopping-cart-list" v-show="shoppingCart.length > 0">
                <div
                    class="shopping-cart-box"
                    v-for="(item, index) in shoppingCart"
                    :key="index"
                >
                  <div class="shopping-cart-img">
                    <img :src="item.img"/>
                  </div>
                  <div class="shopping-cart-info">
                    <div class="shopping-cart-title">
                      <p>{{ item.title.substring(0, 22) }}...</p>
                    </div>
                    <div class="shopping-cart-detail">
                      <p>
                        套餐:
                        <span class="shopping-cart-text">
                          {{ item.package }}
                        </span>
                        数量:
                        <span class="shopping-cart-text">
                          {{ item.count }}
                        </span>
                        价钱:
                        <span class="shopping-cart-text">
                          {{ item.price }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="go-to-buy">
                  <Button type="error" size="small" @click="goToPay">
                    去结账
                  </Button>
                </div>
              </div>
            </DropdownMenu>
          </Dropdown>
        </li>
        <li>
          <router-link to="/">{{ $t('导航') }}</router-link>
        </li>
        <!-- <li><router-link to="/freeback">{{ $t('反馈')}}</router-link></li> -->
        <li>
          <Dropdown placement="bottom-start">
            <a href="javascript:void(0)" class="flex">
              <earth theme="outline" size="18" fill="#4a90e2" strokeLinejoin="bevel" strokeLinecap="square"/>
              <span style="margin-left: 5px">{{ localLang.language }}</span>
            </a>
            <DropdownMenu slot="list">
              <div class="city">
                <p v-for="(lang, index) in langList" :key="`lang${index}`">
                  <span
                      class="city-item"
                      @click="changeLang(lang)"
                  >{{ lang.language }}</span
                  >
                </p>
              </div>
            </DropdownMenu>
          </Dropdown>
        </li>
        <li class="enter-btn" style="line-height: 1;" v-if="sysConfig.is_currency == 1">
          <Dropdown placement="bottom-start">
            <!-- <a href="javascript:void(0)" class="flex">{{ $t('货币')}}</a> -->
            <img class="hl" src="../../../static/img/hl.png"/>
            <DropdownMenu slot="list">
              <span class="Dropdown-btn" v-for="(item, index) in currencyList" :key="index"
                    @click="onCurrencyChange(item)">{{ item.currency_name }}</span>
            </DropdownMenu>
          </Dropdown>
        </li>
        <li class="enter-btn">
          <Dropdown placement="bottom-start">
            <a href="javascript:void(0)" class="flex">{{ $t('我要入驻') }}</a>
            <DropdownMenu slot="list">
              <span class="Dropdown-btn" @click="toEnter('ApplyShop')">{{ $t('我要开店') }}</span>
              <!-- <span class="Dropdown-btn" @click="toEnter('ApplySupplier')">{{ $t('申请供货商')}}</span> -->
            </DropdownMenu>
          </Dropdown>
        </li>
<!--        <li class="first" @click="showIm">-->
<!--          <Badge class="msg" :count="msgCount" :overflow-count="9">-->
<!--            <a>{{ $t('客服') }}</a>-->
<!--          </Badge>-->
<!--        </li>-->
      </ul>
    </div>
  </div>
</template>

<script>
import store from "@/vuex/store";
import {mapActions, mapMutations, mapState} from "vuex";
import {Earth, Translate} from "@icon-park/vue"

export default {
  name: "M-Header",

  props: {
    from: {
      type: String,
      default: ''
    }
  },
  components: {
    Translate, Earth
  },
  data() {
    return {
      city: "北京市",
      cityArr: [
        ["北京", "上海", "天津", "重庆", "广州"],
        ["深圳", "河南", "辽宁", "吉林", "江苏"],
        ["江西", "四川", "海南", "贵州", "云南"],
        ["西藏", "陕西", "甘肃", "青海", "陕西"],
      ],
      sysConfig: '',
      currencyList: [],
      serviceInfo: {}
    };
  },
  computed: {
    ...mapState(["userInfo", "shoppingCart", "langList", "localLang", 'token', 'msgCount']),
  },

  created() {
    if (this.token) {
      this.getConfig()
      this.getServiceUserToken()
    }

    this.getCurrencyList()
  },

  methods: {
    ...mapActions(["signOut", "isLogin"]),
    ...mapMutations(['SET_LOCAL_LANG']),
    changeCity(city) {
      this.city = city;
    },

    getConfig() {
      this.$http.getConfig().then(res => {
        if (res.status == 200) {
          this.sysConfig = res.data
        }
      })
    },

    getCurrencyList() {
      this.$http.getCurrencyList().then(res => {
        if (res.status == 200) {
          this.currencyList = res.data
        }
      })
    },

    onCurrencyChange(e) {
      this.$store.commit('SET_CURRENCY', e)

      this.$nextTick(() => {
        location.reload()
      })
    },

    getServiceUserToken() {
      this.$http.getServiceUserToken().then(res => {
        if (res.status == 200) {
          this.serviceInfo = res.data
        }
      })
    },

    changeLang(lang) {
      this.SET_LOCAL_LANG(lang)
      location.reload();
    },
    goToPay() {
      this.$router.push("/order");
    },
    myInfo() {
      this.$router.push("/home");
    },
    signOutFun() {
      this.signOut();
      this.$router.push("/");
    },

    toEnter(index) {
      if (!this.token) {
        this.$router.push('/loginApplyShop')
      } else {
        this.$router.push('/my?name=' + index)
      }
    },

    showIm() {
      if (!this.token) {
        this.$router.push('/login')
      } else {
        if (this.sysConfig.web_url_type == 1) {

          window.open(this.sysConfig.web_url)
        } else {
          this.$root.$children[0].showIm({
            userId: this.serviceInfo.service_user_token,
            userName: this.serviceInfo.user_name,
            userAvatar: this.serviceInfo.headimgurl
          })
        }
      }
    }
  },
  store,
};
</script>

<style scoped lang="scss">
.box {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  background-color: #282828;
  font-size: 12px;
}

.hl {
  width: 18px;
  height: 18px;
}

::v-deep .msg .ivu-badge-count {
  top: 0;
  height: 17px;
  line-height: 17px;
  font-size: 10px;
  box-shadow: none;
}

.Dropdown-btn {
  display: block;
  line-height: 30px;
  padding: 0 10px;
  color: #515a6e;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    background: #f3f3f3;
  }
}

.enter-btn {
  cursor: pointer;
  font-size: 12px !important;
}

.nav {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nav > ul {
  display: flex;
  align-items: center;
  list-style: none;
}

.nav li {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 15px;
  line-height: 40px;
  padding: 0 15px;
  color: #b4b4b4;

  &:after {
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
    width: 1px;
    height: 15px;
    background: #555;
    transform: translate(0, -50%);
    content: '';
  }

  &:first-child:after {
    display: none;
  }
}

.nav a {
  text-decoration: none;
  /* border-left: 1px solid #555555; */
  cursor: pointer;
  color: #b4b4b4;
  font-size: 12px;
}

.location a {
  border-left: none;
}

.nav a:hover {
  color: #f00;
}

.location {
  color: #999999;
}

.icon {
  color: #d9534f;
}

.first {
  color: #999999;
}

.first a:first-child {
  padding: 0 3px;
  border-left: none;
}

.city {
  padding: 10px 15px;
}

.city-item {
  font-weight: bold;
  cursor: pointer;
  padding: 5px;
}

.city-item:hover {
  color: #d9534f;
}

.person-icon {
  color: #d9534f;
  background-color: #f0cdb2;
}

.username {
  color: #999999;
}

.shopping-cart-list {
  padding: 10px 15px;
}

.shopping-cart-box {
  margin: 8px 0px;
  margin-top: 15px;
  padding-bottom: 15px;
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px #e7e3e7 dotted;
}

.shopping-cart-box:first-child {
  margin-top: 8px;
}

.shopping-cart-img {
  margin-right: 15px;
  width: 40px;
  height: 40px;
}

.shopping-cart-img img {
  width: 100%;
}

.shopping-cart-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  width: 200px;
  overflow: hidden;
  font-size: 12px;
  line-height: 20px;
  color: #999999;
}

.shopping-cart-detail {
  color: #999999;
}

.shopping-cart-text {
  color: #e7e3e7;
}

.go-to-buy {
  display: flex;
  justify-content: flex-end;
}

.shopping-cart-null {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cart-null-icon {
  font-size: 38px;
  margin-bottom: 15px;
}

.shopping-cart-null span {
  color: #999999;
  font-size: 12px;
  line-height: 16px;
}

.username-p {
  cursor: pointer;
}

.my-page {
  padding: 3px 5px;
  width: 180px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-page a {
  margin: 0px;
  padding: 0px;
  border: none;
}

.my-info {
  padding: 5px;
  width: 50%;
  height: 100%;
  text-align: center;
  cursor: pointer;
}

.my-info:hover {
  box-shadow: 0px 0px 5px #e7e3e7;
}

.my-info i {
  font-size: 28px;
}

.my-info p {
  font-size: 12px;
}

.sign-out {
  padding: 5px;
  width: 50%;
  height: 100%;
  text-align: center;
  cursor: pointer;
}

.sign-out:hover {
  box-shadow: 0px 0px 5px #e7e3e7;
}

.sign-out i {
  font-size: 28px;
}

.sign-out p {
  font-size: 12px;
}
</style>
