<template>
  <div class="empty-wrap">
    <img :src="getType" alt="跨境电商" :width="size+'px'" :height="size+'px'"/>
    <p>{{$t(desc)}}</p>
  </div>
</template>


<script>
import * as emptyImgs from "./img";
export default {
  name: "Empty",
  computed: {
    getType() {
      return emptyImgs[this.type];
    },
  },
  created(){

  },
  props: {
    type: {
      type: String,
      default: "NoData",
    },
    desc: {
      type: String,
      default: "暂无数据",
    },
    customeSrc: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "400",
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-wrap {
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:column;
  margin:10px auto;
}
</style>
