import Vue from 'vue';
import Vuex from 'vuex';
import {createVuexPersistedState} from "vue-persistedstate";
import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

Vue.use(Vuex);

const state = {
    isLoading: false, // 是否展示loading动画
    orderBy: 'sale', // 根据什么字段排序
    goodsInfo: { // 商品详情
        goodsImg: [],
        title: '',
        tags: [],
        discount: [],
        promotion: [],
        remarksNum: 0,
        setMeal: [],
        hot: [],
        goodsDetail: [],
        param: [],
        remarks: []
    },
    msgCount: 0,
    token: '',
    userInfo: { // 用户信息
        username: ''
    },
    signUpStep: 0, // 登陆步骤
    marketing: { // 营销
        CarouselItems: [], // 轮播图
        activity: [] // 活动
    },
    seckills: { // 秒杀
        deadline: {
            hours: 0,
            minute: 0,
            seconds: 0
        },
        goodsList: []
    },
    computer: {}, // 电脑专栏
    eat: {}, // 爱吃专栏
    asItems: [], // 广告
    goodsList: [], // 商品列表
    shoppingCart: [], // 购物车
    newShoppingCart: [], // 刚加入的购物车，作为展示
    recommend: [], // 推荐购买
    cartList: [], // 购物车列表
    cartNum: 0,
    addressList: [], //收获地址列表
    curAddressIndex: 0,
    orderData: {},
    myBalance: 0,
    langList: [],
    localLang: {
        code: 'en'
    },
    currency: {
        currency_code: '',
        currency_name: '',
        currency_symbol: ''
    },
    searchKey: '',
    nationCodeData: {
        country_code: 86,
        country_img: '/static/images/china.jpg'
    },
    search_name: '1'
}
export default new Vuex.Store({
    plugins: [
        createVuexPersistedState({
            key: 'vuex',
            storage: window.localStorage,
            ...state
        }),
    ],
    state,
    getters,
    actions,
    mutations
});
