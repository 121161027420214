/**
 * @Description: WoShop商城
 * @Author: Parker
 * @Copyright: 武汉一一零七科技有限公司©版权所有
 * @Link: www.wo-shop.net
 * @Contact: QQ:2487937004
 */
import _axios from './config';
import md5 from 'md5';
import {SECRETKEY} from '../config/index';
import store from '@/vuex/store';

const baseAppoint = SECRETKEY;

const device_token = '';

let api_token = null;

function request (url, data = {}, method = 'POST') {
  let lang = store.getters.getLocalLang.code;
  console.log(lang);
  switch (lang) {
    case 'zh-Hans': // 中文简体
      lang = 'zh-cn';
      break;
    case 'en': // 英文
      lang = 'en-us';
      break;
    case 'zh-Hant': // 中文繁体
      lang = 'zh-Hant';
      break;
    case 'ja': // 日语
      lang = 'ja';
      break;
    case 'ko': // 韩语
      lang = 'ko';
      break;
    case 'de': // 德语
      lang = 'de';
      break;
    case 'fr': // 法语
      lang = 'fr';
      break;
    case 'es': // 西班牙语
      lang = 'es';
      break;
    case 'ru': // 俄语
      lang = 'ru';
      break;
    case 'th': // 泰语
      lang = 'th';
      break;
    case 'pt': // 葡萄牙语
      lang = 'pt';
      break;
    case 'vnm': // 越南语
      lang = 'vnm';
      break;
    case 'ara': // 阿拉伯语
      lang = 'ara';
      break;
    case 'may': // 马来语
      lang = 'may';
      break;
    case 'ida': // 印尼语
      lang = 'ida';
      break;
  }
  api_token = md5(`${url}${baseAppoint}`);
  return _axios.request(
    {
      url: `${url}?lang=${lang}&currency=${store.state.currency.currency_code}`,
      method,
      data: {
        ...data,
        api_token,
        client_id: 1,
        client_type: 'pc',
        token: store.state.token || ''
      }
    },
  );
}

export default {
  returnCommonParams (apiUrl = '', name = 'common') {
    return {
      api_token: md5(`${apiUrl}${baseAppoint}`),
      client_id: 1,
      token: store.state.token || '',
      device_token,
      name
    };
  },

  /**
   * 获取退换货订单详情接口
   *
   * @param {any} data
   * @returns
   */
  getthorderinfo (data) {
    return request('api/MemberOrder/thorderinfo', data);
  },

  /**
   * 余额明细记录列表接口
   *
   * @param {any} data
   * @returns
   */
  getlist (data) {
    return request('api/Detail/getlist', data);
  },
  /**
   * 获取入驻审核状态信息接口
   *
   * @param {any} data
   * @returns
   */
  getapplystatusSupplier (data) {
    return request('api/ApplyInfoSupplier/applystatus', data);
  },
  /**
   * 申请入驻获取相关信息
   */
  getRuzhuinfoSupplier (data) {
    return request('api/ApplyInfoSupplier/ruzhuinfo', data);
  },
  /**
   * 申请供货商入驻申请
   */
  applySupplier (data) {
    return request('api/ApplyInfoSupplier/applySupplier', data);
  },
  /**
   * 用户收货接口
   *
   * @param {any} data
   * @returns
   */
  getsurehuan (data) {
    return request('api/ThApply/surehuan', data);
  },
  /**
   * 用户退货发货接口
   *
   * @param {any} data
   * @returns
   */
  getthfahuo (data) {
    return request('api/ThApply/thfahuo', data);
  },
  /**
   * 填写物流信息调用信息接口
   *
   * @param {any} data
   * @returns
   */
  getwuliuinfo (data) {
    return request('api/ThApply/getwuliuinfo', data);
  },
  /**
   * 撤销退换申请接口
   *
   * @param {any} data
   * @returns
   */
  getchexiao (data) {
    return request('api/ThApply/chexiao', data);
  },

  /**
   *  登录用户获取自己手机号
   *
   * @param {any} data
   * @returns
   */
  getUserPhone (data) {
    return request('api/MemberInfo/getUserPhone', data);
  },
  /**
   * 微信小程序通过code换取openid
   *
   * @param {any} data
   * @returns
   */
  getWechatMiniProgramOpenid (data) {
    return request('api/Login/getWechatMiniProgramOpenid', data);
  },

  thirdLogin (data) {
    return request('api/Login/thirdLogin', data);
  },
  /**
   * 发布商品评价
   *
   * @param {any} data
   * @returns
   */
  getaddgoodscomment (data) {
    return request('api/Comment/addGoodsComment', data);
  },
  /**
   * 我的商品评价
   *
   * @param {any} data
   * @returns
   */
  getMyGoodsCommentList (data) {
    return request('api/Comment/myGoodsCommentList', data);
  },
  /**
   * 获取退换货申请详情接口
   *
   * @param {any} data
   * @returns
   */
  getapplyinfo (data) {
    return request('api/ThApply/applyinfo', data);
  },
  /**
   * 获取退换货原因接口
   *
   * @param {any} data
   * @returns
   */
  getreason (data) {
    return request('api/ThApply/getreason', data);
  },
  /**
   * 获取申请退款信息接口
   *
   * @param {any} data
   * @returns
   */
  getthindex (data) {
    return request('api/ThApply/thindex', data);
  },
  /**
   * 退换货申请方式信息接口
   *
   * @param {any} data
   * @returns
   */
  getthtypeShop (data) {
    return request('api/ThApply/getReturnType', data);
  },
  /**
   * 取消关注
   *
   * @param {any} data
   * @returns
   */
  getcancelShowcang (data) {
    return request('api/MemberColl/cancelcoll', data);
  },

  /**
   * 删除订单接口
   *
   * @param {any} data
   * @returns
   */
  getdelorder (data) {
    return request('api/MemberOrder/delorder', data);
  },
  /**
   * 确认收货接口
   *
   * @param {any} data
   * @returns
   */
  getqrshouhuo (data) {
    return request('api/MemberOrder/qrshouhuo', data);
  },
  /**
   * 找回密码接口
   *
   * @param {any} data
   * @returns
   */
  getfindpwd (data) {
    return request('api/MemberInfo/findpwd', data);
  },
  /**
   * 找回密码发送验证码接口
   *
   * @param {any} data
   * @returns
   */
  getfindpwdcode (data) {
    return request('api/MemberInfo/findpwdcode', data);
  },
  /**
   * 支付订单接口 保证金
   *
   * @param {any} data
   * @returns
   */
  getorderzhifu (data) {
    return request('api/ApplyInfo/orderzhifu', data);
  },
  /**
   * 获取入驻审核状态信息接口
   *
   * @param {any} data
   * @returns
   */
  getapplystatus (data) {
    return request('api/ApplyInfo/applystatus', data);
  },
  /**
   * 提现明细接口
   *
   * @param {any} data
   * @returns
   */
  getTxmxtxinfo (data) {
    return request('api/Withdraw/txinfo', data);
  },
  /**
   * 余额提现记录列表接口
   *
   * @param {any} data
   * @returns
   */
  getWithdrawList (data) {
    return request('api/Withdraw/getWithdrawList', data);
  },
  /**
   * 佣金提现记录列表接口
   *
   * @param {any} data
   * @returns
   */
  getCommissionWithdrawList (data) {
    return request('api/DistributionUser/getWithdrawList', data);
  },
  /**
   * 充值记录列表接口
   *
   * @param {any} data
   * @returns
   */
  getRechargeList (data) {
    return request('api/Recharge/getRechargeList', data);
  },
  /**
   * 申请提现接口
   *
   * @param {any} data
   * @returns
   */
  sendwithdrawSubmit (data) {
    return request('api/DistributionUser/withdrawSubmit', data);
  },
  /**
   * 我的钱包接口
   *
   * @param {any} data
   * @returns
   */
  getWallet () {
    return request('api/Wallet/index');
  },
  /**
   * 获取钱包余额
   *
   * @param {any} data
   * @returns
   */
  getWalletMoney () {
    return request('api/Wallet/getWalletMoney');
  },
  /**
   * 获取用户积分
   *
   * @param {any} data
   * @returns
   */
  getUserIntegral () {
    return request('api/MemberInfo/getUserIntegral');
  },
  /**
   * 取消关注商家接口
   *
   * @param {any} data
   * @returns
   */
  getshopcancelcoll (data) {
    return request('api/CollShops/cancelcoll', data);
  },
  /**
   * 关注商家接口
   *
   * @param {any} data
   * @returns
   */
  getshopcoll (data) {
    return request('api/CollShops/coll', data);
  },

  /**
   * 取消关注商品接口
   *
   * @param {any} data
   * @returns
   */
  getcancelcoll (data) {
    return request('api/CollGoods/cancelcoll', data);
  },
  /**
   * 关注商品接口
   *
   * @param {any} data
   * @returns
   */
  getcoll (data) {
    return request('api/CollGoods/coll', data);
  },
  /**
   * 获取我的关注列表
   *
   * @param {any} data
   * @returns
   */
  getshoucanglst (data) {
    return request('api/MemberColl/shoucanglst', data);
  },

  /**
   * 用户优惠券列表接口
   *
   * @param {any} data
   * @returns
   */
  getcouponlstshop (data) {
    return request('api/MemberCoupon/couponlst', data);
  },
  /**
   * 通过行业获取经营类目接口
   *
   * @param {any} data
   * @returns
   */
  getcates (data) {
    return request('api/ApplyInfo/getcates', data);
  },
  /**
   * 申请入驻获取相关信息
   *
   * @param {any} data
   * @returns
   */
  getruzhuinfo (data) {
    return request('api/ApplyInfo/ruzhuinfo', data);
  },
  /**
   * 判断申请入驻状态
   *
   * @param {any} data
   * @returns
   */
  getpanduan (data) {
    return request('api/ApplyInfo/panduan', data);
  },
  /**
   * 解绑银行卡接口
   *
   * @param {any} data
   * @returns
   */
  getdeletecard (data) {
    return request('api/BankCard/deletecard', data);
  },
  /**
   * 获取银行卡信息接口
   *
   * @param {any} data
   * @returns
   */
  getBankCardinfo (data) {
    return request('api/BankCard/index', data);
  },
  /**
   * 添加银行卡接口
   *
   * @param {any} data
   * @returns
   */
  getBankCardAdd (data) {
    return request('api/BankCard/add', data);
  },
  /**
   * 修改支付密码接口
   *
   * @param {any} data
   * @returns
   */
  geteditpaypwd (data) {
    return request('api/MemberInfo/editpaypwd', data);
  },
  /**
   * 重置支付密码发送短信接口
   *
   * @param {any} data
   * @returns
   */
  getczpaypwdcode (data) {
    return request('api/MemberInfo/czpaypwdcode', data);
  },
  /**
   * 设置支付密码接口
   *
   * @param {any} data
   * @returns
   */
  setPayPwd (data) {
    return request('api/MemberInfo/setPayPwd', data);
  },
  /**
   * 修改手机号
   *
   * @param {any} data
   * @returns
   */
  editPhone (data) {
    return request('api/MemberInfo/editPhone', data);
  },
  /**
   * 设置支付密码发送短信接口
   *
   * @param {any} data
   * @returns
   */
  getszpaypwdcode (data) {
    return request('api/MemberInfo/szpaypwdcode', data);
  },
  /**
   * 更换手机号码接口
   *
   * @param {any} data
   * @returns
   */
  geteditphone (data) {
    return request('api/MemberInfo/editphone', data);
  },
  /**
   * 修判断用户支付密码设置与否接口
   *
   * @param {any} data
   * @returns
   */
  hasPayPwd (data) {
    return request('api/MemberInfo/hasPayPwd', data);
  },
  /**
   * 修改登录密码接口
   *
   * @param {any} data
   * @returns
   */
  geteditpwd (data) {
    return request('api/MemberInfo/editpwd', data);
  },

  /**
   * 获取主页信息接口
   *
   * @param {any} data
   * @returns
   */
  gethomeInfo (data) {
    return request('api/Index/indexInfo', data);
  },
  /**
   * 获取首页顶部导航列表
   *
   */
  getIndexNavList () {
    return request('api/Index/getIndexNavList');
  },
  /**
   * 主页获取商品信息接口
   *
   * @param {any} data
   * @returns
   */
  getGoodsList (data) {
    return request('api/Index/getGoodsList', data);
  },
  /**
   * 即将开始秒杀商品详情
   *
   * @param {any} data
   * @returns
   */
  getrushgoodinfo (data) {
    return request('api/Seckill/rushgoodinfo', data);
  },
  /**
   * 根据时间段获取秒杀商品列表接口
   *
   * @param {any} data
   * @returns
   */
  getActivity (data) {
    return request('api/Seckill/index', data);
  },
  /**
   * 根据时间段获取秒杀商品列表接口
   *
   * @param {any} data
   * @returns
   */
  getSeckillTime (data) {
    return request('api/Seckill/getSeckillTime', data);
  },
  /**
   * 获取商家促销活动信息接口
   *
   * @param {any} data
   * @returns
   */
  getprominfo (data) {
    return request('api/Shops/getPromotionInfo', data);
  },

  /**
   * 获取商家店铺详细接口
   *
   * @param {any} data
   * @returns
   */
  getshopinfo (data) {
    return request('api/Shops/getShopInfo', data);
  },
  /**
   * 获取商家分类接口
   *
   * @param {any} data
   * @returns
   */
  getallgoods (data) {
    return request('api/Shops/getShopGoodsList', data);
  },
  /**
   * 获取商家分类接口
   *
   * @param {any} data
   * @returns
   */
  getshopcates (data) {
    return request('api/Shops/shopcates', data);
  },
  /**
   * 获取商家介绍信息接口
   *
   * @param {any} data
   * @returns
   */
  getShopBaseInfo (data) {
    return request('api/Shops/getShopBaseInfo', data);
  },

  /**
   * 获取店铺活动商品列表接口
   *
   * @param {any} data
   * @returns
   */
  getprolst (data) {
    return request('api/Shops/getPromotionGoodsList', data);
  },
  /**
   * 通过分类获取商家列表接口
   *
   * @param {any} data
   * @returns
   */
  getcatelst (data) {
    return request('api/Shops/getcatelst', data);
  },

  /**
   * 支付订单获取订单信息接口
   *
   * @param {any} data
   * @returns
   */
  getzhifuorder (data) {
    return request('api/MemberOrder/zhifuorder', data);
  },

  /**
   * 订单详情接口
   *
   * @param {any} data
   * @returns
   */
  getMyorderinfo (data) {
    return request('api/MemberOrder/getOrderInfo', data);
  },

  /**
   * 获取店铺门店
   *
   * @param {any} data
   * @returns
   */
  getShopStore (data) {
    return request('api/MemberOrder/getShopStore', data);
  },
  /**
   * 取消订单接口
   *
   * @param {any} data
   * @returns
   */
  getMyOrderquxiao (data) {
    return request('api/MemberOrder/quxiao', data);
  },
  /**
   * 物流详情接口
   *
   * @param {any} data
   * @returns
   */
  getLogisticsInfo (data) {
    return request('api/Logistics/kdNiao', data);
  },
  /**
   * 所有订单列表接口
   *
   * @param {filter} 订单类型  6:全部订单  1:待付款   2:待发货    3:待收货   4:待评价
   * @param {page} 分页
   * @returns 订单列表
   */
  getMyOrder (data) {
    return request('api/MemberOrder/index', data);
  },

  /**
   * 支付订单接口
   *
   * @param {any} data
   * @returns
   */
  getzhifu (data) {
    return request('api/Order/zhifu', data);
  },
  /**
   * 购物车创建订单支付接口
   *
   * @param {any} data
   * @returns
   */
  getaddorderPay (data) {
    return request('api/Order/addorder', data);
  },
  /**
   * 立即购买创建订单接口
   *
   * @param {any} data
   * @returns
   */
  getpuraddorderPay (data) {
    return request('api/Order/puraddorder', data);
  },

  /**
   * 搜索商品接口
   *
   * @param {any} data
   * @returns
   */
  getsearchgoods (data) {
    return request('api/Search/searchGoods', data);
  },
  /**
   * 获取商品服务项接口
   *
   * @param {any} data
   * @returns
   */
  getserlst (data) {
    return request('api/Sertion/serlst', data);
  },
  /**
   * 获取商品活动信息接口
   *
   * @param {any} data
   * @returns
   */
  gethuodonginfo (data) {
    return request('api/Promotion/huodonginfo', data);
  },
  /**
   * 领取优惠券接口
   *
   * @param {any} data
   * @returns
   */
  getcoupons (data) {
    return request('api/Coupon/getcoupons', data);
  },
  /**
   * 获取优惠券列表信息接口
   *
   * @param {any} data
   * @returns
   */
  getcouponlst (data) {
    return request('api/Coupon/couponlst', data);
  },
  /**
   * 搜索商家店铺
   *
   * @param {any} data
   * @returns
   */
  getsearchshops (data) {
    return request('api/Search/searchshops', data);
  },
  /**
   * 立即购买确认订单详情接口
   *
   * @param {any} data
   * @returns
   */
  getpursure (data) {
    return request('api/Order/pursure', data);
  },
  /**
   * 立即购买确认订单接口
   *
   * @param {any} data
   * @returns
   */
  getpurbuy (data) {
    return request('api/Order/purbuy', data);
  },
  /**
   * 购物车购买确认订单详情接口
   *
   * @param {any} data
   * @returns
   */
  getcartsure (data) {
    return request('api/Order/cartsure', data);
  },
  /**
   * 购物车购买确认订单接口
   *
   * @param {any} data
   * @returns
   */
  getcartbuy (data) {
    return request('api/Order/cartbuy', data);
  },
  /**
   * 个人中心基本信息接口
   *
   * @param {any} data
   * @returns
   */
  getUserInfo (data) {
    return request('api/MemberInfo/readprofile', data);
  },
  /**
   * 会员新增收货地址接口
   *
   * @param {any} data
   * @returns
   */
  getAddressdel (data) {
    return request('api/Address/del', data);
  },
  /**
   * 会员新增收货地址接口
   *
   * @param {any} data
   * @returns
   */
  getAddressadd (data) {
    return request('api/Address/add', data);
  },
  /**
   * 获取区县接口
   *
   * @param {any} data
   * @returns
   */
  getarea (data) {
    return request('api/Address/getarea', data);
  },
  /**
   * 获取城市接口
   *
   * @param {any} data
   * @returns
   */
  getcity (data) {
    return request('api/Address/getcity', data);
  },
  /**
   * 获取省份接口
   *
   * @param {any} data
   * @returns
   */
  getpro (data) {
    return request('api/Address/getpro', data);
  },
  /**
   * 获取会员单个收货地址信息接口
   *
   * @param {any} data
   * @returns
   */
  getAddressinfo (data) {
    return request('api/Address/getinfo', data);
  },

  /**
   * 会员编辑收货地址接口
   *
   * @param {any} data
   * @returns
   */
  getAddressedit (data) {
    return request('api/Address/edit', data);
  },

  /**
   * 收货地址列表接口
   *
   * @param {any} data
   * @returns
   */
  getAddressList (data) {
    return request('api/Address/index', data);
  },

  /**
   * 账号密码登录接口
   *
   * @param {any} data
   * @returns
   */
  getPwdLogin (data) {
    return request('api/Login/login', data);
  },

  /**
   * 短信验证码登录接口
   *
   * @param {any} data
   * @returns
   */
  getSmsLogin (data) {
    return request('api/Login/smsLogin', data);
  },

  /**
   * 获取短信验证码
   *
   * @param {any} data
   * @returns
   */
  getSmsCode (data) {
    return request('api/SmsCode/send', data);
  },

  /**
   * 检验短信验证码
   *
   * @param {any} data
   * @returns
   */
  checkSmsCode (data) {
    return request('api/SmsCode/checkSmsCode', data);
  },

  /**
   * 注册app接口
   *
   * @param {any} data
   * @returns
   */
  getRegister (data) {
    return request('api/Register/register', data);
  },

  /**
   * 平台商品分类接口
   *
   * @param {any} data
   * @returns
   */
  getCategory (data) {
    return request('api/Category/index', data);
  },

  /**
   * 通过顶级分类id获取子类接口
   *
   * @param {any} data
   * @returns
   */
  getCategoryList (data) {
    return request('api/Category/getchild', data);
  },

  /**
   * 商品列表接口
   *
   * @param {any} data
   * @returns
   */
  getCategoryGoodsList (data) {
    return request('api/Goods/getCategoryGoodsList', data);
  },

  /**
   * 我的签到信息接口
   *
   * @param {any} data
   * @returns
   */
  getSignInfo (data) {
    return request('api/Sign/signInfo', data);
  },
  /**
   * 我的签到记录接口
   *
   * @param {any} data
   * @returns
   */
  recordList (data) {
    return request('api/Sign/signLog', data);
  },
  /**
   * 我的积分明细
   *
   * @param {any} data
   * @returns
   */
  getIntegralList (data) {
    return request('api/MemberInfo/getIntegralList', data);
  },

  /**
   * 点击签到（1连续签到0普通签到）
   *
   * @param {any} data
   * @returns
   */
  getdosign (data) {
    return request('api/Sign/dosign', data);
  },
  /**
   * 商品详情页
   *
   * @returns
   */
  getgoodsinfo (data) {
    return request('api/Goods/goodsInfo', data);
  },

  /**
   *根据商品规格获取商品价格、库存信息
   * @returns
   */
  getGoodsPrice (data) {
    return request('api/Goods/getGoodsPrice', data);
  },

  /**
   *获取商品规格库存列表
   * @returns
   */
  getSkuList (data) {
    return request('api/Goods/getGoodsSkuList', data);
  },

  /**
   * 获取拼团订单详情接口
   *
   * @param {any} data
   * @returns
   */
  getPinTuanDetail (data) {
    return request('api/MemberAssem/info', data);
  },

  /**
   * 获取拼团规则
   *
   * @returns
   */
  getAssembleRule () {
    return request('api/AssemContent/info');
  },

  /**
   * 获取邀请好友信息
   *
   * @param {any} data
   * @returns
   */
  getInviteInfo (data) {
    return request('api/MemberAssem/yaoqing', data);
  },

  /**
   *  加入购物车
   *
   * @returns
   */
  getaddcart (data) {
    return request('api/Cart/addcart', data);
  },

  /**
   *  购物车商品列表
   *
   * @returns
   */
  getCartList (data) {
    return request('api/Cart/index', data);
  },

  /**
   *  删除购物车商品
   *
   * @returns
   */
  getdelcart (data) {
    return request('api/Cart/delcart', data);
  },

  /**
   *  购物车商品总数
   *
   * @returns
   */
  getgetnum (data) {
    return request('api/Cart/getnum', data);
  },

  /**
   *  修改购物车商品购买数量接口
   *
   * @returns
   */
  geteditcart (data) {
    return request('api/Cart/editcart', data);
  },
  /**
   * 获取主播列表
   *
   * @returns
   */
  getAnchorList (data) {
    return request('api/Live/getAnchorList', data);
  },

  /**
   * 获取推荐直播间列表
   *
   * @returns
   */
  getRecommendLiveRoom (data) {
    return request('api/Live/getRecommendLiveRoom', data);
  },
  /**
   * 获取新人榜
   *
   * @returns
   */
  getLiveList (data) {
    return request('api/Live/getLiveList', data);
  },
  /**
   * 获取短视频
   *
   * @returns
   */
  getVideoList (data) {
    return request('api/Video/getVideoList', data);
  },

  /**
   * 获取直播分类列表
   *
   */
  getLiveNavList () {
    return request('api/Live/gettype');
  },

  /**
   * 发起直播
   *
   */
  launchlive (data) {
    return request('api/Live/launchlive', data);
  },
  /**
   * 发起直播
   *
   */
  hasRoom () {
    return request('api/Live/hasLiveRoom');
  },
  /**
   * 获取主播礼物排行榜
   */
  getGiftRankList (data) {
    return request('api/live/giftsranking', data);
  },
  /**
   * 判断是否开启第三方登录
   */
  getThirdLogin () {
    return request('api/Login/openThirdLogin');
  },
  /**
   * 获取直播店铺的信息
   *
   * @param {any} data
   * @returns
   */
  getLiveShopInfo (data) {
    return request('api/Live/liveinformation', data);
  },

  /**
   * 我要下单消息推送
   * @param {any} data
   * @returns
   */
  tuisong (data) {
    return request('api/Live/tuisong', data);
  },

  /**
   * 获取直播商品列表
   *
   * @param {any} data
   * @returns
   */
  getLiveGoodsList (data) {
    return request('api/Live/getLiveGoodsList', data);
  },
  /**
   * 获取直播在线客服
   *
   * @param {any} data
   * @returns
   */
  getLiveRoomServiceList (data) {
    return request('api/Live/liveRoomServiceList', data);
  },
  /**
   * 获取直播礼物列表
   *
   * @param {any} data
   * @returns
   */
  getLiveGiftList () {
    return request('api/Live/liveGifts');
  },

  /**
   * 获取未完成订单列表
   *
   * @param {any} data
   * @returns
   */
  getNotOrderList (data) {
    return request('api/MemberOrder/index', data);
  },

  /**
   * 分销用户接口
   *
   * @returns
   */
  getDistributionInfo () {
    return request('api/DistributionUser/distribCenter');
  },
  /**
   * 分销用户接口
   *
   * @returns
   */
  getDiffLevelUserList (data) {
    return request('api/DistributionUser/getDiffLevelUserList', data);
  },
  /**
   * 分销级别接口
   *
   * @returns
   */
  getLevelTab () {
    return request('api/DistributionUser/getLevelTab');
  },

  /**
   * 获取分销团队信息
   *
   * @returns
   */
  getDistributionStatus (data) {
    return request('api/DistributionUser/getDistributionStatus', data);
  },

  /**
   * 分销订单
   *
   * @param {any} data
   * @returns
   */
  getDistributionOrderList (data) {
    return request('api/MemberFenxiao/getorder', data);
  },

  /**
   * 我的核销订单列表
   *
   * @param {any} data
   * @returns
   */
  checkOrderRecord (data) {
    return request('api/Order/checkOrderRecord', data);
  },

  /**
   * 根据核销码查看订单详情
   *
   * @param {any} data
   * @returns
   */
  checkOrderDetail (data) {
    return request('api/Order/checkOrderDetail', data);
  },

  /**
   * 确认核销
   *
   * @param {any} data
   * @returns
   */
  checkOrderCode (data) {
    return request('api/Order/checkOrderCode', data);
  },

  /**
   * 分销订单
   *
   * @param {any} data
   * @returns
   */
  getwithdrawInfo () {
    return request('api/DistributionUser/withdrawInfo');
  },
  /**
   * 申请成为分销商
   *
   * @param {any} data
   * @returns
   */
  submitDistribution (data) {
    return request('api/DistributionUser/submitDistribution', data);
  },
  /**
   * 获取佣金明细
   *
   * @param {any} data
   * @returns
   */
  getCommissionList (data) {
    return request('api/DistributionUser/getCommissionList', data);
  },
  /**
   * 分销佣金详情接口
   *
   * @param {any} data
   * @returns
   */
  getCommissionDetails () {
    return request('api/DistributionUser/getCommissionDetails');
  },

  /**
   * 退货
   *
   * @param {any} data
   * @returns
   */
  exchange (data) {
    return request('api/ThApply/pinapplytui', data);
  },

  /**
   * 团购栏目列表
   *
   * @returns
   */
  getAssembleCate () {
    return request('api/Assemble/getcate');
  },

  /**
   * 根据分类获取拼团商品列表
   *
   * @param {any} data
   * @returns
   */
  getAssembleList (data) {
    return request('api/Assemble/getGoodsList', data);
  },

  /**
   * 根据分类获取团购商品列表
   *
   * @param {any} data
   * @returns
   */
  getTgList (data) {
    return request('api/GroupBuy/getGoodsList', data);
  },

  getShopList (data) {
    return request('api/Shops/getShopList', data);
  },
  /**
   * 根据分类获取拼团商品列表
   *
   * @returns
   */
  getArticleByTitle (data) {
    return request('api/Article/getArticleByTitle', data);
  },

  /**
   * 获取banner点击的详情页
   *
   * @returns
   */
  getArticleById (data) {
    return request('api/Article/getArticleById', data);
  },

  /**
   * 找回密码提交接口
   *
   * @returns
   */
  findBackPwd (data) {
    return request('api/MemberInfo/findBackPwd', data);
  },

  /**
   * 找回密码发送短信接口
   *
   * @returns
   */
  findBackPwSms (data) {
    return request('api/MemberInfo/findBackPwSms', data);
  },
  /**
   * 直播关注列表接口
   *
   * @returns
   */
  getFollowLive (data) {
    return request('api/Live/getFollowLive', data);
  },
  /**
   * 平台客服热线接口
   *
   * @returns
   */
  getserviceHotline () {
    return request('api/Index/getServiceHotline');
  },
  /**
   * 个人中心--投诉
   *
   * @returns
   */
  addReport (data) {
    return request('api/Report/addReport', data);
  },
  /**
   * 个人中心--投诉
   *
   * @returns
   */
  getLiveReport (data) {
    return request('api/Live/report', data);
  },
  /**
   * 删除商品评价
   *
   * @returns
   */
  getdeleteGoodsComment (data) {
    return request('api/Comment/deleteGoodsComment', data);
  },
  /**
   * 绑定子账号
   * @returns
   */
  getaddSubaccount (data) {
    return request('api/Merchant/bindSubAccount', data);
  },
  /**
   * 添加子账号
   *
   * @returns
   */
  customerServiceList () {
    return request('api/Merchant/customerServiceList');
  },
  /**
   * 添加子账号
   *
   * @returns
   */
  deleteCustomerService (data) {
    return request('api/Merchant/deleteCustomerService', data);
  },
  /**
   * 商家购物车接口
   *
   * @returns
   */
  getShopperList (data) {
    return request('api/Merchant/orderList', data);
  },
  /**
   * 商家详情接口
   *
   * @returns
   */
  getShopperDetail (data) {
    return request('api/Shops/getShopInfo', data);
  },
  /**
   * 商家详情接口
   *
   * @returns
   */
  getorderCount (data) {
    return request('api/Merchant/orderCount', data);
  },
  /**
   * 商家 购物车订单详情
   *
   * @returns
   */
  getRoleOrderInfo (data) {
    return request('api/Merchant/orderInfo', data);
  },
  /**
   * 商家 购物车订单详情
   *
   * @returns
   */
  getCartApraiseList (data) {
    return request('api/Find/cartApraiseList', data);
  },
  /**
   * 商家 购物车订单详情
   *
   * @returns
   */
  getnotificationList (data) {
    return request('api/Notification/notificationList', data);
  },
  /**
   * 商家 购物车订单详情
   *
   * @returns
   */
  getPrePayOrder (data) {
    return request('api/Recharge/createOrder', data);
  },
  /**
   * 商家 购物车订单详情
   *
   * @returns
   */
  getNotificationInfo (data) {
    return request('api/Notification/notificationInfo', data);
  },
  /**
   * 商家 购物车订单详情
   *
   * @returns
   */
  getShareData () {
    return request('api/Share/shareData');
  },
  /**
   * 商家 获取成功的订单  获取失败的订单
   *
   * @returns
   */
  getShopperOrderList (data) {
    return request('api/Merchant/orderList', data);
  },
  /**
   * 退款
   *
   * @returns
   */
  getaddthorder (data) {
    return request('api/ThApply/addthorder', data);
  },
  /**
   * 退款
   *
   * @returns
   */
  getShareOk () {
    return request('api/Live/shareOk');
  },

  /**
   * 退款
   *
   * @returns
   */
  getFindReport (data) {
    return request('api/Report/findReport', data);
  },
  /**
   * 退款
   *
   * @returns
   */
  getRoomCartGoods (data) {
    return request('api/Cart/roomCartGoods', data);
  },

  /**
   * 获取直播间信息
   *
   * @param {any} data
   * @returns
   */
  getLiveinformation (data) {
    return request('api/Live/liveinformation', data);
  },

  /**
   * 获取直播间信息
   *
   * @param {any} data
   * @returns
   */
  getaddMemberMobile (data) {
    return request('api/Login/addMemberMobile', data);
  },

  /**
   * 获取聊天记录
   */
  getChatMsglist (data) {
    return request('api/ChatMessage/chatlist', data);
  },
  /**
   * 获取商品评价列表
   *
   * @param {any} data
   * @returns
   */
  getGoodsCommentList (data) {
    return request('api/Comment/goodsCommentList', data);
  },
  /**
   * 获取商品评价列表
   *
   * @param {any} data
   * @returns
   */
  getIndexShowcase (data) {
    return request('api/Index/getIndexShowcase', data);
  },
  /**
   * 获取商品评价列表
   *
   * @param {any} data
   * @returns
   */
  getRecommendShops (data) {
    return request('api/Shops/getRecommendShops', data);
  },
  getShopCommentList (data) {
    return request('api/Comment/getShopCommentList', data);
  },
  //获取店铺关注数据
  getFollowShops (data) {
    return request('api/Shops/getFollowShopList', data);
  },
  getFindCate (data) {
    return request('api/Find/findcate', data);
  },
  getNewsList (data) {
    return request('api/NewPublish/getNewsList', data);
  },
  getMenuList (data) {
    return request('api/NavMenu/getMenuList', data);
  },
  getScoreUserInfo (data) {
    return request('api/IntegralShop/getUserInfo', data);
  },
  getScoreTaskList (data) {
    return request('api/IntegralShop/getTaskList', data);
  },
  getIntegralGoodsList (data) {
    return request('api/IntegralShop/getGoodsList', data);
  },
  getIntegralRecordList (data) {
    return request('api/IntegralShop/getIntegralRecordList', data);
  },
  getBannerImage (data) {
    return request('api/NavMenu/getBannerImage', data);
  },
  // 余额提现
  getBalanceWithdrawInfo (data) {
    return request('api/Withdraw/index', data);
  },
  // 余额提现
  doWithdraw (data) {
    return request('api/Withdraw/doWithdraw', data);
  },
  // 佣金提现
  withdrawSubmit (data) {
    return request('api/DistributionUser/withdrawSubmit', data);
  },
  // 通过广告位标识获取广告
  getAdByTag (data) {
    return request('api/Ad/getAdByTag', data);
  },
  // 微信小程序获取手机号
  getPhoneNumber (data) {
    return request('api/Login/getUserPhone', data);
  },
  //获取小程序分销二维码
  getWechatMiniProgramQrcode () {
    return request('api/MemberInfo/getWechatMiniProgramQrcode');
  },
  applyShop (data) {
    return request('api/ApplyInfo/applyShop', data);
  },
  registerApplyShop (data) {
    return request('/api/Register/applyShop', data);
  },
  assembleActivityStatus (data) {
    return request('api/Assemble/pindanInfo', data);
  },
  editprofile (data) {
    return request('api/MemberInfo/editprofile', data);
  },
  getUserInfoByPhone (data) {
    return request('api/MemberInfo/getUserInfoByPhone', data);
  },
  getLangList () {
    return request('api/Lang/getLangList');
  },
  getLangKeyValue (data) {
    return request('api/Lang/getLangKeyValue', data);
  },
  getCountryDefault (data) {
    return request('api/Country/getCountryDefault', data);
  },
  getCountryList () {
    return request('api/Country/getCountryList');
  },
  getAssets () {
    return request('api/Assets/index');
  },
  getPlayVideoList (data) {
    return request('api/Video/getPlayVideoList', data);
  },
  // 获取我的团队列表
  getMyTeam (data) {
    return request('api/DistributionUser/getDiffLevelUserList', data);
  },
  // app版本更新接口
  getVersion (data) {
    return request('api/Version/getVersionInfo', data);
  },
  // 获取热门搜索
  getHotSearch (data) {
    return request('api/Search/getHotSearch', data);
  },
  // 获取短视频评论列表
  getVideoCommentList (data) {
    return request('api/Video/getCommentList', data);
  },
  // 短视频发表评论
  addVideoComment (data) {
    return request('api/Video/addComment', data);
  },
  // 短视频发表评论
  deleteVideoComment (data) {
    return request('api/Video/deleteComment', data);
  },
  // 短视频点赞
  doVideoLike (data) {
    return request('api/Video/like', data);
  },
  // 获取开关配置
  getShowConfig (data) {
    return request('api/Config/getShowConfig', data);
  },
  // USDT配置接口
  getUsdtConfig (data) {
    return request('api/UsdtConfig/getUsdtConfig', data);
  },
  // 获取支付方式开启状态
  getPayOpenStatus (data) {
    return request('api/Pay/getPayOpenStatus');
  },
  // 创建供货商入驻支付订单
  addApplyPayOrderSupplier (data) {
    return request('api/ApplyInfoSupplier/addApplyPayOrder');
  },
  // 创建商家入驻支付订单
  addApplyPayOrder (data) {
    return request('api/ApplyInfo/addApplyPayOrder');
  },
  // 获取开关配置
  addVideo (data) {
    return request('api/Video/addVideo', data);
  },
  // 获取首页领券中心列表
  getHomeCouponList (data) {
    return request('api/PcIndex/couponList', data);
  },
  getPcIndexShowcase (data) {
    return request('api/PcIndex/getIndexShowcase', data);
  },
  getPcIndexGoodsHome (data) {
    return request('api/PcIndex/goodsHome', data);
  },
  getPcGoodsList (data) {
    return request('api/PcIndex/getGoodsList', data);
  },
  getPcCategoryList (data) {
    return request('api/PcIndex/categoryList', data);
  },
  //优惠劵列表
  getCouponList (data) {
    return request('api/Coupon/couponlst', data);
  },
  //新品首发 推荐商品
  getTagGoodsList (data) {
    return request('api/Goods/getTagGoodsList', data);
  },
  //获取网站配置信息
  pcWebConfig (data) {
    return request('api/PcIndex/pcWebConfig', data);
  },
  //订单支付轮询
  searchGetOrder (data) {
    return request('api/PcIndex/searchGetOrder', data);
  },
  // 获取聊天记录
  getChartMsgList (data) {
    return request('api/Im/getMessageList', data);
  },

  uploadImg (data) {
    return request('api/Common/uploadPic', data);
  },
  //获取配置信息
  getConfig (data) {
    return request('api/Config/getConfig', data);
  },
  /**
   * 获取平台客服用户token
   *
   * @returns
   */
  getServiceUserToken () {
    return request('api/Index/getServiceUserToken');
  },

  /**
   * 货币列表
   *
   * @returns
   */
  getCurrencyList () {
    return request('api/Currency/getCurrencyList');
  },

  getIpLocation () {
    return request('/api/Config/getIpLocation');
  },
  bankcardlist(data) {
		return request("api/BankCard/bankcardlist", data);
	},
};
