<template>
  <!-- 登录页面 -->
  <div class="container-wrap">
    <div class="login-container">
      <div class="form-box">
        <div class="form-tab">
          <span class='form-tab-name'>{{ $t('欢迎注册') }}</span>
        </div>
        <div>
          <div class="form-item" prop="username">
            <selectCountryList/>
            <input v-model="formDate.phone" :placeholder="$t('请输入手机号')" class="form-input" type="text"/>
          </div>
          <div v-if="is_phone == 1" class="form-item" prop="password">

            <input v-model="formDate.phonecode" :placeholder="$t('请输入验证码')" class="form-input" type="password"/>
            <span class="get-code" @click="sendSmsCode()">{{ $t('获取验证码') }}</span>
          </div>
          <div class="form-item" prop="password">
            <input v-model="formDate.password" :placeholder="$t('请输入密码')" class="form-input" type="password"/>
          </div>
          <div class="policy">
            <div class="policy-left" @click="onPolicyCheck">
              <Icon v-if="agreeCheck" color="#e2231a" size="21" type="md-checkmark-circle"/>
              <span v-if="!agreeCheck" class="policy-dot"/>
              {{ $t('我已阅读并同意') }}
            </div>
            <router-link :to="{path:'/ArticleDetail',query:{title:'用户隐私政策'}}" target="_blank">
              <span class="policy-a"> {{ $t('《用户隐私政策》') }} </span>
            </router-link>
          </div>
          <Button class="main-btn" long size="large" type="error" @click="handleSubmit('formInline')">{{ $t('注册') }}
          </Button>
        </div>
        <p class="bottom">
          <router-link :to="{path:'login'}">
            <span class="bottom-link">{{ $t('返回登录') }}</span>
          </router-link>
          <router-link :to="{path:'emailRegister'}">
            <span class="bottom-link">{{ $t('邮箱注册') }}</span>
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from './mixins.js';
import {mapActions} from 'vuex';
import * as phonenumbers from 'libphonenumber-js';

export default {
  name: 'Login',
  mixins: [mixins],
  data() {
    return {
      agreeCheck: false,
      loginType: 'account',
      is_phone: 1,
      is_email: 1,
    };
  },
  created() {
    this.type = 1
    this.getShowConfig();
  },
  components: {
    selectCountryList: () => import('./countryList')
  },
  methods: {
    ...mapActions(['login']),
    tabChange(type) {
      this.loginType = type
    },
    handleSubmit(name) {
      const {formDate: {phone, phonecode, password}, agreeCheck} = this
      if (!phone) {
        return this.$Message.error(this.$t('请输入手机号'))
      }
      const phoneNumberStr = '+' + this.$store.state.nationCodeData.country_code + phone

      const phoneNumber = phonenumbers.parse(phoneNumberStr);
      const isValid = phonenumbers.isValidNumber(phoneNumber);

      if (!isValid) {
        return this.$Message.error(this.$t('请输入正确的手机号'))
      }
      if (!password.length) {
        return this.$Message.error(this.$t('请输入密码'))
      }
      if (password.length < 6) {
        return this.$Message.error(this.$t('请输入不小于6位数的密码'))
      }
      if (!agreeCheck) {
        return this.$Message.error(this.$t('请勾选协议'))
      }
      this.$http.getRegister({phone, password, phonecode}).then(res => {
        const {status, mess} = res
        if (status === 200) {
          this.$Message.success(mess)
          this.$router.push({path: '/login', query: {phone}})
        }
      })
    },
    /**
     * 获取后台的配置信息
     */
    getShowConfig() {
      this.$http.getShowConfig().then(res => {
        if (res.status === 200) {
          res.data.map(item => {
            if (item.ename == 'is_email') {
              this.is_email = parseInt(item.value)
              console.log(this.is_email)
            }
            if (item.ename == 'is_phone') {
              this.is_phone = parseInt(item.value)
              console.log(this.is_phone)
            }
          })
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.container {
  &-wrap {
    width: 100%;
    margin: 0 auto;
    height: 545px;
    position: relative;
    overflow: hidden;
  }

  &-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}

.login-container {
  position: absolute;
  right: 200px;
  top: 50%;
  transform: translateY(-50%);
}

.form {
  &-box {
    padding: 40px 50px;
    margin: 30px auto;
    background-color: #Fff;
    border-radius: 4px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .14);
  }

  &-tab {
    display: flex;
    cursor: pointer;
    justify-content: space-around;
    margin-bottom: 30px;

    &-name {
      font-size: 28px;
      font-weight: 500;
      transition: all 0.4s;

      &-active {
        color: #e2231a;
      }
    }
  }
}

.main-btn {
  height: 50px;
  background-color: #e2231a;
  border-radius: 0;
}

.form {
  &-item {
    display: flex;
    align-items: center;
    border: 1px solid #dcdcdc;
    margin: 20px 0;
  }

  &-input {
    width: 240px;
    border: none;
    padding: 0 15px;
    height: 48px;
    outline: 0;
    color: #666;

  }

  &-label {
    padding: 0 24px;
    height: 48px;
    line-height: 48px;
    border-right: 1px solid #dcdcdc
  }
}

.bottom {
  display: flex;
  justify-content: space-between;
  text-align: center;
  color: #bcbcbc;
  margin-top: 20px;

  &-link {
    color: #e2231a;
  }
}

.policy {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;

  &-a {
    color: #e2231a;
  }

  &-left {
    display: flex;
    align-items: center;

  }

  &-dot {
    width: 16px;
    height: 16px;
    border-radius: 80px;
    border: 1px solid #dcdcdc;
    flex-shrink: 0;
    display: inline-block;
    margin-right: 5px;
  }

}

.get-code {
  border-left: 1px solid #dcdcdc;
  padding: 0 8px;
  cursor: pointer;
}

::v-deep .ivu-input-wrapper {
  width: 100%;
}

</style>
