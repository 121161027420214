import { render, staticRenderFns } from "./Brand.vue?vue&type=template&id=45b6a103&scoped=true"
import script from "./Brand.vue?vue&type=script&lang=js"
export * from "./Brand.vue?vue&type=script&lang=js"
import style0 from "./Brand.vue?vue&type=style&index=0&id=45b6a103&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45b6a103",
  null
  
)

export default component.exports