import Vue from "vue";
import Router from "vue-router";
import Index from "@/pages/Index";

const Login = resolve => require(["@/pages/Login"], resolve);
const Empty = resolve => require(["@/pages/Empty"], resolve);
const Register = resolve => require(["@/pages/Register"], resolve);
const CheckPhone = resolve => require(["@/pages/signUp/CheckPhone"], resolve);
const InputInfo = resolve => require(["@/pages/signUp/InputInfo"], resolve);
const SignUpDone = resolve => require(["@/pages/signUp/SignUpDone"], resolve);
const GoodsList = resolve => require(["@/pages/GoodsList"], resolve);
const GoodsDetail = resolve => require(["@/pages/GoodsDetail"], resolve);
const ShoppingCart = resolve => require(["@/pages/ShoppingCart"], resolve);
const Order = resolve => require(["@/pages/Order"], resolve);
const Pay = resolve => require(["@/pages/Pay"], resolve);
const PayDone = resolve => require(["@/pages/PayDone"], resolve);
const Freeback = resolve => require(["@/pages/Freeback"], resolve);
const ShopStreet = resolve => require(["@/pages/home/ShopStreet"], resolve);
const Merchant = resolve => require(["@/pages/Merchant"], resolve);
const My = resolve => require(["@/pages/my/My"], resolve);
const Cart = resolve => require(["@/pages/cart/Cart"], resolve)
const OrderDetail = resolve => require(["@/pages/my/order/OrderDetail"], resolve)
const ArticleDetail = resolve => require(["@/pages/ArticleDetail"], resolve)
const CouponList = resolve => require(["@/pages/coupon/CouponList"], resolve)
const ProductGoods = resolve => require(["@/pages/productGoods/goodsList"], resolve)
const LoginApplyShop = resolve => require(["@/pages/LoginApplyShop"], resolve)

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: "/", // 首页
            name: "Index",
            component: Index
        },
        {
            path: "/Login", // 登录
            name: "Login",
            component: Login
        },
        {
            path: "/empty", // 登录
            name: "Empty",
            component: Empty
        },
        {
            path: "/emailRegister",
            name: "emailRegister",
            component: resolve => require(["@/pages/EmailRegister"], resolve)
        },
        {
            path: "/Register", // 注册
            name: "Register",
            component: Register,
            children: [
                {
                    path: "/",
                    name: "index",
                    component: CheckPhone
                },
                {
                    path: "checkPhone",
                    name: "CheckPhone",
                    component: CheckPhone
                },
                {
                    path: "inputInfo",
                    name: "InputInfo",
                    component: InputInfo
                },
                {
                    path: "signUpDone",
                    name: "SignUpDone",
                    component: SignUpDone
                }
            ]
        },
        {
            path: "/goodsList", // 商品列表
            name: "GoodsList",
            component: GoodsList
        },
        {
            path: "/goodsDetail", // 商品详情
            name: "GoodsDetail",
            component: GoodsDetail
        },
        {
            path: "/shoppingCart", // 商品详情
            name: "ShoppingCart",
            component: ShoppingCart
        },
        {
            path: "/order", // 订单页面
            name: "Order",
            component: Order
        },
        {
            path: "/pay", // 支付页面
            name: "Pay",
            component: Pay
        },
        {
            path: "/payDone", // 支付成功页面
            name: "PayDone",
            component: PayDone
        },
        {
            path: "/freeback", // 反馈页面
            name: "Freeback",
            component: Freeback
        },
        {
            path: "/ShopStreet", // 店铺街
            name: "ShopStreet",
            component: ShopStreet,
        },
        {
            path: "/merchant",  //店铺详情
            name: "Merchant",
            component: Merchant
        },
        {
            path: "/my",
            name: "my",
            component: My
        },
        {
            path: "/Cart",
            name: "Cart",
            component: Cart
        },
        {
            path: "/OrderDetail",
            name: "OrderDetail",
            component: OrderDetail
        },
        {
            path: "/ArticleDetail",
            name: "ArticleDetail",
            component: ArticleDetail
        },
        {
            path: "/coupon/CouponList",//优惠劵列表
            name: "CouponList",
            component: CouponList,
        },
        {
            path: "/productGoods/goodsList",  //新品首发 推荐单品 热门
            name: "ProductGoods",
            component: ProductGoods,
        },
        {
            path: "/loginApplyShop",  // 注册开店
            name: "LoginApplyShop",
            component: LoginApplyShop,
        }
    ]
})

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default router
